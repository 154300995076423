import React from 'react'
import type { NativeLinkElementType } from './nativeLinkType'
import { PluginsType } from '../../scripts/plugins/pluginTypes'

export default function NativeLink({
  href,
  type,
  children,
  className,
  target,
  dataAttributes = {},
  editor,
  show = true,
}: NativeLinkElementType) {
  if (!show) {
    return null
  }
  if (href && !editor) {
    const resolvedTarget = type === 'anchor' ? '_self' : target

    return (
      <a
        href={href}
        target={resolvedTarget}
        className={className}
        {...dataAttributes}
      >
        {children}
      </a>
    )
  }

  return <>{children}</>
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}
